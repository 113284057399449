<template>
  <fragment> 
    <region ref="child" v-if="role.isVolvo"/>
    <regionDefault ref="child" v-else />
  </fragment> 
</template>

<script>
import Vue from 'vue'
import {getRole} from '@/utils/getRole';
import region from './region.vue';
import regionDefault from './regionDefault.vue';


export default {
  data() {
      return {
        role: getRole(),
      }
  },
  components:{
    region,
    regionDefault,
  },
  methods: {
    getDataBySearch(q) {
      this.$refs.child && this.$refs.child.getDataBySearch(q);
    },
  },
  created() {
  },
  mounted () {
  },
}
</script>

<style lang="less">

</style>


